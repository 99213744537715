import React from 'react'
import Gitview from "./components/Gitview";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <>
  
    <Gitview/>
    <Footer/>
    </>
  )
}

export default App;
