import React from 'react'
import '../index.css'
function Footer() {
   
    return (
       <>
       <footer id="footer">
        <span>Created By <a href="_blank">M.K Creation</a> | <span class="far fa-copyright"></span> 2021 All rights
            reserved.</span>
    </footer>
       </>
    )
}

export default Footer